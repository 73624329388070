.callToAction {
  margin-top:40px;
}

.iconBox {
  margin-bottom:40px;

  .iconBox_icon {
    background: $c-purple;
    margin: 0 auto;
    display: table;
    -webkit-transition: background 0.2s, color .2s;
    -moz-transition: background 0.2s, color .2s;
    transition: background 0.2s, color .2s;
    font-size: 35px;
    line-height: 90px;
    cursor: pointer;
    width: 90px;
    height: 90px;
    text-align: center;
    position: relative;
    color: $c-lighterGray;
    z-index: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    span {
      z-index: 2;
      color: $c-lighterGray;
      font-size: 40px;
    }
  }

  .iconBox_content {

    h3 {
      margin: 15px 0 20px;
      font-size: 22px;
      font-weight:700;
    }
    .iconBox_text {
      height:100px !important;

      @include mqSmallerThan(tabletLandscape) {
        height:auto !important;
      }
    }
    .iconBox_button {
      margin: 10px auto 20px;
    }
  }
}

.missionStatement {
  margin:80px auto;
  max-width:70%;

  p {
    color:$c-darkGray;
    @include serif;
    font-size: 22px;
  }

  @include mqSmallerThan(tabletLandscape) {
    max-width:90%;
  }
}