.searchPage {

  label{
    display: block;
    @extend %sansSerif;
    @include fontsize(14);
    margin:0;
    letter-spacing: 0.1rem;
    padding-left:.6em;
    text-transform: uppercase;
  }

  input, select {
    @extend %sansSerif;
    @include fontsize(14);
    background-color: white;
  }

  .imageLoader {
    width:95px;
    float:left;
    margin-right:10px;
  }

  table {
    width:100%;
    @extend %sansSerif;
    @include fontsize(14);
    background-color: $c-white;
    margin-bottom: 1rem;
    border-collapse: collapse;
    /*border-radius:16px;*/

    thead {
      width:100%;
      background-color: $gray-200;
      @extend %serif;
      @include fontsize(14);
      letter-spacing: 0.1rem;
    }

    .sort {
      display: inline-block;

      svg {
        width:20px;
        fill:$gray-800;
      }
    }

    .h4 {
      @include fontsize(18);
      margin: 0;
    }

    tr {
      border-bottom:1px solid $gray-300;
    }

    .column-60 {
      width:60%;
    }
    .column-50 {
      width:50%;
    }
    .column-25 {
      width:25%;
    }
    .column-16 {
      width:16%;
    }
    .column-8 {
      width:8%;
    }

    th,
    td {
      padding:.75em;
    }

    a {
      text-decoration: none;

      &:hover {
        opacity: 0.5;
      }
    }

    .subTitle {
      @extend %sansSerif;
      @include fontsize(14);
      letter-spacing: 0.1rem;
      margin-bottom:0;
    }

    .imageLoader {
      overflow: visible;
      height:100px;
    }
  }

  .pageInfo {
    @include fontsize(16);
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    justify-content: space-between;

    p {
      margin:0;
      @extend %sansSerif;
    }

    .pageInfo__Nav {
      display: flex;
      align-items: center;
      --tw-space-x-reverse: 0;
      margin-right: calc(1rem * var(--tw-space-x-reverse));
      margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
      line-height: 20px;

      nav {
        margin-left:10px;
        @extend %sansSerif;
        position: relative;
        display: inline-flex;
        --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

        a {
          text-decoration: none;
          position: relative;
          display: inline-flex;
          align-items: center;
          padding: 0.5rem 1rem;
          border: 1px solid $gray-300;
          background-color: $c-white;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 500;
          color: $gray-600;

          svg {
            height: 20px;
            width: 20px;
          }

          &:hover {
            text-decoration: none;
            background-color: $gray-200;
          }

          &.pageButton {
            margin-left:-1px;
          }

          &.pagePrevious {
            border-top-left-radius: 0.375rem;
            border-bottom-left-radius: 0.375rem;
            padding: 0.5rem;
          }

          &.pageNext {
            border-top-right-radius: 0.375rem;
            border-bottom-right-radius: 0.375rem;
            padding: 0.5rem;
          }

          &.active {
            background-color: $gray-100;
            color: $gray-600;
          }
        }
      }
    }
  }
}