.siteHeader {
  width: 100%;
  position: relative;
  transition: all .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out;

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  &.toolbar__out {
    top:-200px;
    z-index:999;
    position:fixed;
    background-color: white;
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0 7px 8px 0 rgba(0,0,0,0.25);
    transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;

    @include mqSmallerThan(tabletLandscape) {
      top:0;
    }
  }

  &.toolbar__in {
    top:0;
    z-index:999;
    position:fixed;
    background:#fff;
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0 7px 8px 0 rgba(0,0,0,0.25);
    transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
  }

  .topbar {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ebebeb;
    font-size: 13px;

    .col-xs-12 {
      line-height:10px;
    }
    .header-widget {
      display:inline-block;
      width:100%;

      p {
        margin: 0;
        font-size: 13px;
      }
    }


    .header-top-social {
      margin: 0;
      list-style-type: none;
      float:right;

      li {
        float: left;
        padding: 11px 12px;
        width: 40px;
        text-align: center;
        border-left: 1px solid #ebebeb;

        a {
          color: #777;
          font-size: 14px;

          &:hover {
            color:#222;;
          }
        }

        &.donate {
          width: auto;
          padding:0;

          a {
            color:#fff;
            font-size:13px;
            font-weight: 700;
            position: relative;
            padding: 12px 30px;
            line-height:40px;
            background-color: #5e0069;

            &:hover {
              background:#222;
            }
          }
        }
      }
    }

    @include mqSmallerThan(tabletLandscape) {
      display: none;
    }
  }

  .mainNav {
    display: flex;
    align-items: center;
    align-content: center;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    padding: 0.5rem 0;

    .siteHeader_logo {

      a {
        display: inline-block;
      }

      @include mqMax(779px) {
        padding-left:1rem;
      }
    }

    .mainNav_links {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      align-content: center;
      @include nakedList;
      @include sansSerif;
      cursor: pointer;
      padding-left:0;
      margin-bottom:0;
      -ms-flex-direction: row;
      flex-direction: row;


      a {
        text-decoration: none !important;
        @include sansSerif;
      }

      .mainNav_item {
        height: 80px;
        line-height: 80px;
        margin: 0 5px;
        display: inline-block;
        text-align: left;
        padding: 0;
        position: relative;

        @include mqMax(1124px) {
          margin: 0 0 0 0;
        }

        a {
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: 0;
          text-transform: none;
          color: #252525;
          display: inline-block;
          padding:10px;

          @include hover-focus() {
            text-decoration: none;
            background-color: $c-lighterGray;
            color:rgba(94,0,105,.6);
          }

          @include mqMax(1038px) {
            padding: 10px 5px;
          }

          &.search {
            svg {
              width:28px;
              height:28px;
              fill: $c-blueGray;
            }

            &:after {
              border:none;
              margin:0;
            }
          }

        }

        .drop-menu {
          visibility: hidden;
          opacity: 0;
          /*visibility: visible;
          opacity: 1;*/
          position: absolute;
          top: calc(100% + 30px);
          /*left: 0;*/
          z-index: 10;
          min-width: 10rem;
          width:200px;
          padding: .5rem 0;
          margin: .125rem 0 0;
          font-size: .8rem;
          color: $c-darkGray;
          text-align: left;
          list-style: none;
          background-color: $c-white;
          box-shadow: -1px 0px 3px 1px rgba(0, 0, 0, .2);
          transition: all .3s;
          -webkit-transition: all .3s;


          li {
            position: relative;
            margin-top: .25rem !important;
            border-bottom: 1px solid $c-lighterGray;
            width: 100%;
            padding:0;
            line-height:1.8;
          }

          > li:hover {
            background-color: $c-lighterGray;
          }

          a {
            @include sansSerif;
            font-size: .8rem;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 0;
            text-transform: none;
            color: #252525;
            padding:10px;

            @include hover-focus() {
              text-decoration: none;
              color:rgba(94,0,105,.6);
            }

            &:after {
              border-top: .3em solid transparent;
              border-right: 0;
              border-bottom: .3em solid transparent;
              border-left: .3em solid;
            }
          }

          li.no-submenu {
            a:after {
              border:none;
            }
          }

          .submenu{
            visibility: hidden;
            opacity: 0;
            position: absolute;
            left: 100%;
            top: -7px;
          }

          > li:hover > .submenu {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
          }

        }

        &:hover .drop-menu {
          visibility: visible;
          opacity: 1;
          top: 100%;
        }

      }

      @include mqSmallerThan(tabletLandscape) {
        display:none;
      }
    }

/*
      a {
        &:after {
          display: inline-block;
          margin-left: .5em;
          vertical-align: 0.1em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          }
        }
*/

  }



  #mobileNav--toggle {
    display:none;
    position: fixed;
    right:40px;
    top:40px;
    text-align: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    transition: transform 0.2s ease-in-out;
    z-index:1001;

    @include mqSmallerThan(tabletPortrait) {
      right:12px;
    }

    a {
      display: block;
      position: absolute;
      width:42px;
      height:46px;
    }

    span, span:before, span:after {
      cursor: pointer;
      border-radius: 1px;
      height: 3px;
      width: 40px;
      background: $c-darkGray;
      position: absolute;
      display: block;
      content: '';
    }

    span {
      margin-top:20px
    }
    span:before {
      top: -12px;
    }

    span:after {
      bottom: -12px;
    }

    @include mqSmallerThan(tabletLandscape) {
      display: block;
    }
  }

  #mobileNav--toggle a span, #mobileNav--toggle a span:before, #mobileNav--toggle a span:after {
    transition: all 200ms ease-in-out;
  }

  #mobileNav--toggle a.nav--active span {
    background-color: transparent;
    color:$c-darkGray;
  }

  #mobileNav--toggle a.nav--active span:before, #mobileNav--toggle a.nav--active span:after {
    top: 0;
    color:$c-darkGray;
  }

  #mobileNav--toggle a.nav--active span:before {
    transform: rotate(45deg);
    color:$c-darkGray;
  }

  #mobileNav--toggle a.nav--active span:after {
    transform: rotate(-45deg);
    color:$c-darkGray;
  }

  .slideIn {
    left:-999em;
    transition: .15s;

    &.drawer--open {
      left:0;
    }
  }

  .subSlideIn {
    position: fixed;
    left:-999em;
    transition: .35s;
    z-index:999;
    background-color: $c-white;
    width:100%;
    height:100%;
    padding:1em;
    top:100px;
    bottom:0;

    &.drawer--open {
      left:0;
    }
  }

  #mobileNav {
    position: fixed;
    top:0;
    bottom:0;
    background-color: $c-white;
    width:100%;
    height:100%;
    padding:1em;
    z-index:999;

    .mobile_siteHeader_logo {
      max-width:240px;
    }
    .mobile_siteHeader_logo_mark {
      display:none;
    }

    &.drawer--open {
      .mobile_siteHeader_logo {
        display: none;
      }
      .mobile_siteHeader_logo_mark {
        display:block;
        max-width:80px;
      }
    }

    .siteHeader_buttons {
      position: relative;
      top:-64px;
      right:96px;
      text-align: right;

      a {
        margin:0 5px;
      }

      @include mqSmallerThan(tabletPortrait) {
        right:56px;
        @include fontsize(12);

        span {
          display: none;
        }
      }

      .btn {
        padding:.5em .75em;
        background-color: $c-red;

        &.watchLive {
          background-color: $c-purple;
        }
      }
    }

    .siteHeader_search {
      position: relative;
      top:-15px;

      .form-group {
        display: flex;
      }

      div.input {
        flex-grow: 1;
      }
      input {
        font-size: .75rem;
        width:100%;
        padding:10px;
        border: 1px solid $c-lightGray;
        background-color:$c-lighterGray;
        box-shadow: inset 1px 1px 1px 0 #adb5bd;
      }
      button {
        margin-left:10px;
        position: relative;
        top:-2px;
      }
    }

    .mobileNav_links {

      li {
        padding:0;
        margin:0;
        list-style: none;

        a {
          text-decoration: none;
          color: $c-darkGray;
          margin: 0 auto;
          display: block;
          margin-bottom:.15em;
          padding-bottom:.15em;
          /*@include fontsize(22);*/

          &.has-submenu {

            &:after {
              display: inline-block;
              margin-left: .5em;
              vertical-align: 0.1em;
              content: "";
              border-top: 0.3em solid transparent;
              border-right: 0;
              border-bottom: 0.3em solid transparent;
              border-left: 0.3em solid;
            }
          }

          &.topNav_item {
            text-transform: uppercase;
            @include fontsize(18);

            &:after {
              vertical-align: 0em;
            }
          }

          &.topNav_item-title {
            @include fontsize(24);
          }
        }

      }

      .goBack {
        @include fontsize(18);
        margin-bottom:20px;

        svg {
          width:16px;
          height:16px;
          margin-top:-5px;
        }
      }
    }
  }
}



.html--noScroll {
  .headerNav {
    height:100%;
  }
}