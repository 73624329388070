
.events-container {
  /*background: #f7f7f7;*/
  padding-top:60px;
  padding-bottom:60px;
}

.search-list .search-result-item,
.events .items-row {
  border-bottom: 1px solid #efefef;
  margin: 0;
  padding: 50px 0 20px;
}

.news-events-container {
  .events-container {
    padding:0;
  }
  .events .items-row {
    padding:0;
  }
}

.search-list .search-result-item:last-child,
.events .items-row:last-child {
  border-bottom: none;
}

.events {
  .col-left .deadline .field-value {
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    color: $c-purple;
    padding-right: 10px;
  }

  .col-left .deadline .field-value span {
    display: block;
  }

  .col-left .deadline .field-value .day {
    font-size: 75px;
    border-right: 1px solid #efefef;
    line-height: 75px;
  }

  .col-left .deadline .field-value .month {
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 5px;
    line-height: 14px;
  }

  .col-left .start-time,
  .col-left .finish-time,
  .col-left .address {
    display: none;
  }

  .col-center .title {
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    color: #222;
    font-weight: bold;
    margin: 0 0 15px;
  }

  .col-center .info-item {
    color: #b5b5b5;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
  }

  .col-center .info-item .start-time:before {
    content: '\f017';
    font-family: "Font Awesome 5 Free";
    padding-right: 5px;
  }

  .col-center .info-item .address {
    padding-left: 0px;
  }

  .col-center .info-item .address:before {
    content: "\f3c5";
    font-family: "Font Awesome 5 Free";
    margin-right: 5px;
  }

  .col-center .info-item .deadline {
    display: none;
  }

  .col-center .introtext {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #777;
  }

  .col-center .introtext a {
    color: #777;
  }

  .col-center .introtext a.btn {
    color: white;
  }
}
