.staff {
  h2, h3, h4, h5, h6 { margin-bottom:5px; }

  .person {
    border-bottom:1px solid $c-lightGray;
    padding-bottom:5px;
    margin-bottom:20px;

    .main-image {
      margin-top:30px;
    }
  }
}

.clergy {
  .person {
    padding-bottom:20px;
  }
}