ul.errors {
  display: none;
}
.has-error .form-control,
.has-error .custom-file-label {
  border-color: #d00 !important;
}
.has-error .form-check-label {
  color: #d00
}
.has-error ul.errors {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inherit;
}
.has-error ul.errors > li {
  font-size: 80%;
  color: #d00;
  margin-top: .25rem;
}