html {
	font-size: 16px
}

#logo {
	padding-top: 20px;
	padding-bottom: 20px
}

body {
	background: transparent
}

body,
.author-name a {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: 0
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: 'Playfair Display', sans-serif;
	font-weight: 700;
	letter-spacing: 0;
	line-height:1.3;
	margin-bottom:30px;
}

h1,
.h1 {
	font-size: 2.5714285714286rem
}

h2,
.h2 {
	font-size: 2.1428571428571rem
}

h3,
.h3 {
	font-size: 1.7142857142857rem
}

h4,
.h4 {
	font-size: 1.4285714285714rem
}

h5,
.h5 {
	font-size: 1.2857142857143rem
}

h6,
.h6 {
	font-size: 1rem
}

p, li {
	line-height: 1.8;
}

ul, ol, p, dl, pre {
	margin-bottom: 25px;
	margin-top:0;
}

small {
	font-size:.7rem;
}

.cart.variations_form td label,
.vc_general.vc_btn3.vc_btn3-style-outline-custom,
.primary-font,
blockquote,
button,
.button,
.tabs li,
thead th,
.edit {
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
	letter-spacing: 0
}

body {
	color: #777777
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	color: $c-darkGray
}

a {
	color: $c-purple;
}

a:hover,
a:focus,
a:active {
	color: $c-purple-hover
}

.box-container {
	max-width:100%;
	margin-right:auto;
	margin-left:auto;
}
@media (min-width:768px){
	.box-container{ max-width:98% }
}
@media (min-width:992px){
	.box-container{ max-width:90% }
}
@media (min-width:1200px){
	.box-container{ max-width:1170px }
}

.primary-nav {
	background: transparent
}

.primary-nav nav>ul>li>a {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0;
	text-transform: none;
	color: #252525
}

.primary-nav nav>ul>li {
	background: transparent
}

.primary-nav nav>ul>li:hover {
	background: transparent
}

.primary-nav nav>ul ul {
	background: $c-white
}

.primary-nav nav>ul ul a {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0;
	text-transform: none;
	color: #252525
}



#main {
	margin-top: 0px
}





.zoo-blog-item .title-post {
	font-family: 'Playfair Display', sans-serif;
	font-size: 1.7857142857143rem;
	font-weight: 700;
	line-height: 1.5;
	letter-spacing: 0;
	text-transform: none;
	color: $c-darkGray
}

.zoo-blog-item .title-post:hover {
	color: $c-red
}

.zoo-blog-item .entry-content {
	color: #777777
}

.readmore {
	color: $c-white
}

.widget-title {
	font-family: 'Playfair Display', sans-serif;
	font-weight: 700
}

.single .title-detail {
	font-family: 'Playfair Display', sans-serif;
	font-weight: 700
}

.woocommerce .woocommerce-breadcrumb a:hover,
.navigation .nav-links .nav-previous a,
.navigation .nav-links .nav-next a,
.post-content a,
.page-numbers:hover,
.page-numbers.current,
.cart.variations_form .reset_variations:hover,
blockquote:before,
#menu-mobile-trigger.active,
.sidebar a:hover,
.tags-link-wrap a,
.instagram-feed-heading i,
.zoo-breadcrumb-container .zoo-breadcrumb-url:hover,
.cp-carousel-layout .clever-portfolio-filter li.active,
.cp-carousel-layout .clever-portfolio-filter li:hover,
.project-single-pagination .back-to-home a:hover,
.clever-portfolio-filter li:hover,
.cp-carousel-layout .clever-portfolio-filter li:hover,
.cp-carousel-layout .clever-portfolio-filter li.active,
.zoo-timeline-shortcode h5.title,
.zoo-team-member .member-position,
.service-content ul li:before,
.zoo-demo-box.textstyle .zoo-header-demo-box i,
.zoo-testimonial-author,
.layout-control-block li a.active,
.layout-control-block li a.disable-sidebar,
.wrap-main-page .pf_rngstyle_html5 .irs-from,
.wrap-main-page .pf_rngstyle_html5 .irs-to,
.wrap-main-page .pf_rngstyle_html5 .irs-single,
.campaign-control-layout .layout-control .control-item a.active,
#top-right-header .header-top-social li:hover > a,
.cvca-shortcode-fun-fact.style-2 .fun-fact-content .fun-fact-item .icon,
.cmm-container .cmm.cmm-theme-main-menu > li:hover > a,
.cmm-container .cmm.cmm-theme-main-menu > li.current-menu-item > a,
.cmm-container .cmm.cmm-theme-main-menu > li.current-menu-ancestor > a,
.cmm-container .cmm.cmm-theme-main-menu li > .cmm-sub-container .sub-menu li > a:hover,
.cmm-container .cmm.cmm-theme-main-menu li > .cmm-sub-container .cmm-sub-wrapper li > a:hover,
.amount,
.tribe_events .zoo-post-inner .event-date-time .time,
.vc_custom_1502870947515 .vc_btn3,
.cvca-shortcode-featured-campaign .remain .amount-remain,
.cvca-shortcode-featured-campaign .zoo-donate-amount .raised ins,
.cvca-shortcode-featured-campaign .zoo-donate-amount .goal ins,
#bottom-footer a,
.cvca-shortcode-fun-fact.style-3 .fun-fact-content .fun-fact-item .icon,
.wrap-header.header-transparent .sticky-wrapper:not(.is-sticky) .primary-nav ul li a span:hover,
.wrap-icon-cart:hover i,
.primary-nav nav li:hover > a,
.zoo-blog-item .title-post:hover,
#site-branding .site-title a:hover,
#main .entry-title a:hover,
.post-info > span a:hover,
.widget ul li a:hover {
	color: $c-purple-hover
}

.btn, .button-primary, .donate-button, input[type=submit] {
	background: $c-purple;
	padding: 0 25px;
	min-height: 40px;
	line-height: 42px;
	text-transform: uppercase;
	color: #fff;
	display: inline-block;
	border: none;
	font-weight: 500;
	letter-spacing: 1px;
	border-radius: 0;
	transition: all .3s;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	-ms-transition: all .3s;
}

.btn:hover,
.button-primary:hover,
.donate-button:hover,
input[type="submit"]:hover, {
	background: #222;
}

.page-numbers:hover,
.page-numbers.current,
.related .zoo-carousel-btn:hover,
.zoo-wrap-pagination.simple a:hover,
#page-load .loading:before,
#page-load .loading,
#main .vc_btn3.vc_btn3-color-info,
#main .vc_btn3.vc_btn3-color-info.vc_btn3-style-flat,
.cvca-tm-shortcode .cvca-wrap-ct-inner:hover,
.widget_newsletterwidget .tnp-widget form input.tnp-submit {
	border-color: $c-purple-hover
}

.vc_custom_1502870947515 .vc_btn3 {
	border: 2px solid $c-purple !important
}

.vc_custom_1502870947515 .vc_btn3 {
	color: $c-purple !important
}

.zoo-progress .bars:after {
	border-top-color: $c-purple 
}

.wrap-project.default .portfolio-info .btn:hover,
.cart.variations_form .reset_variations,
.tags-link-wrap a:hover,
.inpost-pagination a,
.cp-mobile-filter,
.project-single-pagination .project-nav-arrow a,
.clever-portfolio-filter li,
.clever-portfolio-filter li:after,
.cp-carousel-layout .clever-portfolio-filter li,
.vc_tta-panel-heading .vc_tta-panel-title a,
.vc_tta-panel-heading .vc_tta-panel-title a i:after,
.vc_tta-panel-heading .vc_tta-panel-title a,
.two-lines-1 .request-a-quote,
#menu-mobile-trigger,
#comments ol.commentlist .comment .meta strong, {
	color: $c-red
}

.vc_tta-panel-heading .vc_tta-panel-title a i:after,
.search-wrap .ipt,
.zoo-wrap-pagination.simple a {
	border-color: $c-red
}

.zoo_download_block .text-download:hover {
	background-color: $c-red
}

.mini_cart_item .cart-detail,
.mini_cart_item .right-mini-cart-item .remove,
.zoo-single-product-nav .product-link-btn {
	color: $c-white
}

.page-numbers {
	background-color: $c-white
}

.ias-trigger a,
.btn,
input[type="submit"],
button:not(.vc_general),
.button {
	color: $c-white
}

.ias-trigger a:hover,
.btn:hover,
input[type="submit"]:hover,
.donate-button,
.button:hover {
	color: $c-white
}

.ias-trigger a,
.btn,
input[type="submit"],
button:not(.vc_general),
.donate-button,
.button,
#tribe-bar-form .tribe-bar-submit input[type=submit] {
	
}

a.btn:active,
a.btn:visited,
a.btn:link {
	color: $c-white !important;
}

.ias-trigger a:hover,
.btn:hover,
input[type="submit"]:hover,
.button:hover,
.donate-button:hover,
.widget_newsletterwidget .tnp-widget form input.tnp-submit:hover {
	background: $c-darkGray
}

li.header-top-donate {
	padding:0 !important;
	background-color: $c-purple;

	a {
		position: relative;
		top:-2px;
		padding:10px 30px;
		background-color: $c-purple
	}
}

#main-footer .fa { padding-right:10px; }
#main-footer .fa-flip-horizontal { padding-left:10px; padding-right:0; }
#main-footer .fa.fa-map-marker-alt {
	float: left;
	padding: 7px 15px 20px 0px;
}
strong { font-weight:800; }

.float-left {
	float:left;
	text-align:left;
}
.float-right {
	float:right;
	text-align:right;
}
.margin-top {
	margin-top:80px;
}
.margin-bottom {
	margin-bottom:80px;
}

.quoteBox {
	min-height:60vh;

	.container {
		padding-top:140px !important;
		padding-bottom:140px !important;

		@include mqSmallerThan(tabletLandscape) {
			padding-top:40px !important;
			padding-bottom:40px !important;
		}

		h2, p { color:$c-white !important; }
		.title-block {
			span {
				&:after {
					background: $c-white;

					@include mqSmallerThan(tabletLandscape) {
						display:none;
					}
				}
			}
		}
	}
}

.background-box {
	background-color:rgba(0,0,0,0.75);
	padding: 30px 20px;
	max-width: 1000px;
	margin: 0 auto;

	@include mqSmallerThan(tabletLandscape) {
		padding: 20px;
	}

	.title-block {
		font-size: 42px;
		text-align: center;

		span {
			position: relative;
			padding-bottom: 30px;

			&:after {
				background: #ffffff;
				content: '';
				height: 2px;
				width: 70px;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);

				@include mqSmallerThan(tabletLandscape) {
					display:none;
				}
			}
		}
	}

	.description {
		text-align: center;
		margin: 50px 20% 60px;

		@include mqSmallerThan(tabletLandscape) {
			margin: 50px 5% 60px;
		}
	}

}
.parallax {
	position: relative;
}
.parallax__layer {
	position: relative;
	background-attachment: fixed;
	background-position: top center;
	background-size: cover;
}
.parallax__layer--base {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width:100%;
	z-index:100;
}
.parallax__layer--back {
	height : 60vh;

	@include mqSmallerThan(tabletLandscape) {
		height : 130vh;
	}
}

.page-header {
  border-bottom: none;
  margin: 0;
  padding: 0;
}
.page-header a {
  color: #858585;
}
.page-header a:hover,
.page-header a:active,
.page-header a:focus {
  color: #0099FF;
}
.page-header h3.title a {
  color: #252525;
  text-decoration: none;
}

.scrollup-btn {
  display:none;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: #000;
  background-color: rgba(0,0,0,0.5);
  transition: background-color 0.2s ease;
  border-radius: 4px;
  text-align: center;
  line-height:3em;
  z-index:998;

  span {
	position:absolute;
	color:white;
	z-index:999;
	width:32px;
	left:9px;
	top:7px;
	font-size: 34px;
  }

  &:hover { opacity:0.8; }
}

.main-image {
	float:left;
	margin:5px 15px 5px 0;
}

div.anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
}
.space-small { margin-top:20px; }
.space-medium { margin-top:40px; }
.space-large { margin-top:60px; }
.space-extraLarge { margin-top:80px; }
.bottom-space-small { margin-bottom:20px; }
.bottom-space-medium { margin-bottom:40px; }
.bottom-space-large { margin-bottom:60px; }
.bottom-space-extraLarge { margin-bottom:80px; }

h2 + h3 {
	margin-top:-26px;
}

.cvca-blog-item {
	min-height:440px;

	.cvca-post-inner {
		border-bottom: 1px solid #ebebeb;
		width: 100%;
		display: inline-block;
	}

	.wrap-content-post {
		padding: 0 30px;

		h3 {
			font-size: 19px;
			line-height: 1.35;
			margin-bottom: 15px;
			text-decoration: none;
			transition: all .3s;
			-webkit-transition: all .3s;
		}

		.post-info {
			position: absolute;
			top: -36px;
			left: 30px;
			font-size: 12px;
			width: 100%;
			display: inline-block;

			.post-date {
				background: $c-purple;
				color: $c-white;
				padding: 4px 15px;
				display: inline-block;
				font-weight: 700;
				text-transform: uppercase;
				font-size: .8rem;
				margin: 0;
			}
		}
	}
}

.navigation.pagination {
	margin: 0;
	width: 100%;
	padding: 0 15px;

	.nav-links {
		margin-top: 15px;
		width: 100%;

		.page-numbers {
			background-color: #fff;
			height: 35px;
			min-width: 35px;
			display: inline-block;
			text-align: center;
			line-height: 30px;
			font-size: .9333rem;
			color: #999;
			margin-right: 10px;
			border: 2px solid #999;

			&.current {
				border-color:$c-purple;
				color:$c-purple;
			}

			&:hover {
				text-decoration: none;
				border-color:$c-purple;
				color:$c-purple;
			}
		}
	}

}

.gallery {
	display: inline-block;
	width: 100%;
	margin-bottom: 30px;

	.gallery-item {
		text-align: left;
		vertical-align: top;
		margin: 0 0 1.5em;
		padding: 0 1em 0 0;
	}
}

.container {
	overflow: visible !important;
}
.container:after {
	clear: both;
	display: table;
	content: " ";
}

.left, .align-left {text-align:left; }
.right, .align-right { text-align:right; }
.center, .align-center { text-align:center; }

#main {
	figure.left,
	img.left {
		float:left;
		margin:5px 15px 5px 0;
	}
	figure.right,
	img.right {
		float:right;
		margin:5px 0px 5px 15px;
		margin:5px 0px 5px 15px;
	}
	img.center {
		float:none;
		margin:0 auto;
	}
	figure {
		&.left { margin-right:25px; }
		&.right { margin-left:25px; }
		&.center {
			float:none;
			margin:0 auto;
			text-align:center;

			img {
				margin:0 auto 20px;
				float:none;
			}
		}

		figcaption {
			font-size:0.8em;
			text-align:center;
		}
	}

	.row-padding-top-line,
	.row-padding-lines {
		padding-top:70px;
		padding-bottom:40px;
		margin-top:30px;
		margin-bottom:40px;
		border-top:1px solid $c-lighterGray;
		border-bottom:1px solid $c-lighterGray;
	}
	.row-padding-top-line {
		border-bottom:none;
		margin-top:60px;
		padding-top:20px;
	}

	blockquote {
		padding-top:40px;
		padding-bottom:40px;
		margin-top:30px;
		margin-bottom:40px;
		border-top:2px solid $c-lightGray;
		border-bottom:2px solid $c-lightGray;
		background: #fcfcfc;

		&.right {
			float:right;
			width:40%;
			margin-left:20px;
		}
		&.left {
			float:left;
			width:40%;
			margint-right:20px;
		}
		&.center {
			float:none;
			width:100%;
			text-align:center;
		}

		p {
			font-size:20px;
		}
	}

}

#map {
	width:100%;
	height:400px;

	#map-content {
		h5 {
			margin:10px 0 5px;
		}
	}
}

.contact {
	.cvca-demo-box .cvca-header-demo-box > i {
		color:$c-red;
	}
	#map {
		height: 520px;
	}
}

.page {

	.blog-cover {

		padding: 70px 0;
		position: relative;
		background-color: #f3f3f3;
		margin-bottom:40px !important;
		display:flex;

		h1 {
			color: #222222;
		}

		.blog-cover-inner {
			/*position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			text-align: center;
			transform: translate(-50%, -50%);*/
			flex-grow: 1;
			align-items: center;
			align-content: center;
			text-align: center;
		}
	}

	.page-item {

		padding-bottom: 50px;
		margin-bottom: 50px;
		border-bottom: 1px solid $c-lighterGray;

		h3 {
			margin-bottom: 5px;
		}
	}

	.smaller-page-item {
		padding-bottom:0px;
		margin-bottom:30px;
	}

	.first-item {
		margin-top:30px;
		margin-bottom:70px;
	}
}

.page-item-info {
	padding-bottom:10px;
	margin-bottom:50px;
	border-bottom:1px solid $c-lightGray;
	min-height:40px;
}

.prevNext {
  padding-bottom:10px;
  margin-bottom:40px;
  border-top:1px solid $c-lightGray;

  .icons-left, .icons-right { margin-top:4px; }
  .icons-left span { margin-right:10px; }
  .icons-right {
    text-align: right;

  span { margin-left:10px; }
  }
}

.right { float:right; text-align:right; }
.meta { font-size:0.8em; }
.post-back { text-align:right; }

.map-meta { margin-bottom:5px; }

.events-landing .events {
	top:-40px;
}



#search-wrap {
	overflow:hidden;
	position: absolute;
	top: 0;
	left: 0;
	background: #fff;
	width:100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	z-index: 2;

    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.39), 0 0px 0px #FFF, 0 1px 0 #FFF;

	input[type="text"] {
		border: medium none;
		display: block;
		font-size: 27px;
		font-weight: lighter;
		height: 80px;
		padding: 20px;
		width:89%;
		background-color: #fff;
	}

	#search-close {
		float: right;
		text-align:center;
		position: relative;
		top: -110px;
		right: 10px;
		width:10%;
		font-size: 48px;
		color: $c-medGray;
	}
}
#sticky-wrapper.is-sticky {
	#search-close {
		top: -99px;
	}
}

#search-wrap.open-search {
	opacity: 1;
	visibility: visible;
	position:fixed;
	z-index:999;
}

#sticky-wrapper {
	#search-wrap {
		height: 147px;

		form {
			input[type="text"] {
				height: 130px;
			}
		}
	}
}
#sticky-wrapper.is-sticky {
	#search-wrap {
		height: 80px;

		form {
			input[type="text"] {
				height: 80px;
			}
		}
	}
}

@media (max-width: 1200px) {
	.main-menu .hidetablet {
		display: none;
	}
	#site-branding {
		max-width:200px;
		min-width:200px;
	}
}
@media (max-width: 992px) {
	.primary-nav nav > ul > li {
		margin:0;
	}
}
@media (max-width: 880px) {
	.primary-nav nav > ul > li a {
		font-size:0.9em;
	}
	#site-branding {
		max-width:180px;
		min-width:180px;
	}
}

div.ctct-form-embed form.ctct-form-custom div.ctct-form-field {
	margin:0 !important;
}
.mejs__inner {
	position: static !important;}
.mejs__time {
	padding-top:12px !important;}

.nav-links > div.tooltip { border:none !important;}

.news-events-container {
	.box {
		padding:0 1rem;
	}
}


table {
	width: 100%;
	margin-bottom: 20px;

	tbody td, tbody th {
		border-bottom: 1px solid #ebebeb;
	}

	td, th {
		padding: 8px 0;
	}
}

.table.table-striped {
	td, th {
		padding: .75rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}
}

.cvca-demo-box .cvca-header-demo-box {
	position: relative;
	padding-left: 55px;

	>i {
		font-size: 35px;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.page-menu {
	list-style-type:none;
	text-align:center;
	font-size:28px;

	li {
		list-style-type: none;
	}
}