/*
 * Background image tile
 */

.imageLoader {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.imageLoader img {
  display: block;
  width: 100%;
}

.imageLoader_image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index:0;
}

.imageLoader_image.lazyloaded {
  opacity: 1;
  z-index:1;
  transition: opacity .5s ease;
}

.imageLoader_previewImage {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.imageLoader_image.lazyloaded ~ .imageLoader_previewImage {
  opacity: 0;
}

.imageShadow {
  overflow:visible;

  img {
    box-shadow: $imgBoxShadow;
  }
}

.imageLoader.right {
  float:right;
  width:420px;
  max-width:420px;
  margin-left:15px;
  margin-bottom:15px;
}
.imageLoader.left {
  float:left;
  width:420px;
  max-width:420px;
  margin-right:15px;
  margin-bottom:15px;
}

.imageCircle img {
  border-radius: 50%;
}

/*
 * Background image tile
 */

.imageBackgroundLoader {

  width: 100%;
  background-size: cover;
  z-index: 0;

  .imageBackgroundLoader_previewImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: black;
    z-index: 0;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: opacity 1s ease-in-out;
  }

  &.lazyloaded .imageBackgroundLoader_previewImage {
    opacity: 0;
  }

}

.imageBackgroundLoader.lazyloaded {
  opacity: 1;
  z-index:1;
  transition: opacity .5s ease;
}

.backgroundCover {
  background-position: 50% 50%;
}

.img-center {
  text-align:center;

  a {
    display: inline-block;
  }
}