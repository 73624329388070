// Force vertical scrollbar (to avoid jumps on window resize)

html { overflow-y: scroll; }


// Remove text-shadow in selection highlight:
// https://twitter.com/miketaylr/status/12228805301
// (These selection rule sets have to be separate.)

::-moz-selection {
	color: white;
	background: black;
	text-shadow: none;
}

::selection {
	color: white;
	background: black;
	text-shadow: none;
}

// Remove the gap between audio, canvas, iframes, images, videos and the bottom of their containers
// https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

// Remove default fieldset styles.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

// Allow only vertical resizing of textareas.
textarea {
	resize: vertical;
}


// ------ Base styles

* + * {
	/*margin-top: $defaultMargin;*/
}

*,
*:after,
*:before {
	box-sizing: border-box;
}

html {
	background-color: white;
	@extend %sansSerif;
	@extend %antialiased;
	text-rendering: optimizeLegibility;
}

body {
	color: $c-darkGray;
	background-color: white;
	margin: 0 auto;
	overflow-x: hidden;
	overflow-y: hidden;
	display: block;
}

div {
	position: relative;
}

a {
	color: currentColor;
}

i {
	font-style: normal;
}

p {
	margin:1.5em 0;
}

button {
	border: none;
	background: transparent;
}

// Images

img {
	max-width: 100%;
	vertical-align: middle;
}

// Stretch image width
.img-stretch {
	width: 100%;
}

// Perfect circle
.img-circle {
  border-radius: 50%; // set radius in percents
}

input[type="search"] {
	box-sizing: border-box;
}
/*
* {
	transition:
		all $transitionTime ease 0s,
		color $fastTransitionTime linear 0s,
		background-color $fastTransitionTime linear 0s,
		font-size 0s linear 0s;
}

.preventTransitions, .preventTransitions *, html {
	transition:
		color $fastTransitionTime ease,
		background-color $fastTransitionTime ease;
}
*/
// ------ Helpers

.clearfix, %clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}

	zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

%hideText {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.mb, %mb { overflow:hidden; _overflow:visible; zoom:1; }
.mb-body, %mb-body { margin-top: 0; overflow:hidden; _overflow:visible; zoom:1; }
.mb .mb-media, %mb-media { float:left; margin-right: $defaultMargin; margin-top: 0; }
.mb .mb-media img { display:block; }
.mb .mb-media--alt, %mb-media--alt { float:right; margin-left: $defaultMargin; margin-top: 0; }

.hidden { display: none !important; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.invisible { visibility: hidden; }
.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }


%noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

//---------- images ----------
.backgroundCover {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.backgroundContain {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

//---------- vue ----------
[v-cloak] {
	display: none;
}