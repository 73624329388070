#zoo-footer {
  background: $c-darkGray;
  margin-top:100px;
}

#top-footer {
  background: $c-darkGray
}

#main-footer {
  color: $c-white;
  background: $c-darkGray;

  a {
    color: $c-white;

    &:hover {
      color: #888888;
    }
  }

  .btn {
    border:1px solid ;

    &:hover {
      background-color: #1D1D1D;
      border: 1px solid $c-white;
      color: $c-white;
    }
  }


}

.footer-block {
  padding-top: 65px;
  padding-bottom: 20px;

  .footer-widget-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2142857142857rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: uppercase;
    /*font-weight: 400;*/
    color: #fff;
    /*font-size: 1.2rem;*/
    margin-bottom: 25px;
  }

  .menu {
    padding-left:15px;

    li {
      line-height: 30px;
      position: relative;
      list-style: none;

      &:before {
        content: "^";
        font-size: 20px;
        position: absolute;
        left: -15px;
        transform: rotate(90deg);
        z-index: 1;
        color: #fff;
        font-family: 'Playfair Display', sans-serif;
      }
    }
  }

  .menu>li:first-child {
    padding-top: 0;
  }
}

#bottom-footer {
  background: #1D1D1D;
  color: #656565;
  font-size:12px;
  padding: 30px 0;

  a {
    color: $c-red !important;

    &:hover {
      color: $c-darkRed;
    }
  }
}


.socials {
  margin:0;
  padding:0;

  li {
    list-style-type:none;
    display:inline-block;
    width:30px;
    height:30px;
    text-align:center;
    font-size:1.2rem;
    margin:0 10px !important
  }
}
.socials > :first-child {
  margin-left:0 !important;
}

.ctct-form-button {
  margin-top:10px;
}

#gdpr_text,
.ctct-form-footer,
.grecaptcha-badge { display:none !important; }

