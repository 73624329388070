$navMargin: rems(14);
$defaultMargin: rems(14);
$defaultPadding: rems(20);

%antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%serif, .serif {
	font-family: 'Playfair Display', serif;
	font-weight: 400;
}

%sansSerif, .sansSerif {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

@mixin sansSerif {
	@extend %sansSerif;
}

@mixin serif {
	@extend %serif;
}

// ------- COLORS
$c-white: #ffffff;
$c-black: #000000;
$c-red: #BF2E2E;
$c-darkRed: #BF0000;
$c-yellow: #b4d230;
$c-purple: #5e0069;
$c-purple-hover: rgba(94,0,105,0.6);

$c-blueGray: #bed0d3;
$c-lightBlue: #67c4d2;
$c-lightBlue2: #52abb8;
$c-medBlue: #1f4d5b;
$c-darkBlue: #132e39;

$c-lighterGray: #ebebeb;
$c-lightGray: #d9d9d9;
$c-medGray: #bdbdbd;
$c-darkGray: #222222;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

.clearfix, %clearfix {
	clear:both;

	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

.horizontal-line {
	border-top:1px solid $c-lightGray;
	height:0;
	width:100%;
	z-index:21;
	margin-top:30px;
	margin-bottom:30px;
}


/*
 * Shadows
 */

$buttonShadow: 0px 8px 10px -5px rgba(0,0,0,0.5);
$boxShadow: 0px 16px 16px -6px rgba(0,0,0,0.4);
$imgBoxShadow: 0px 16px 12px -10px rgba(0,0,0,0.4);
$textShadow: 0px 3px 8px rgba(0,0,0,0.25);
$svgShadow: drop-shadow( 0px 2px 4px rgba(0, 0, 0, .6));
$svgShadowLite: drop-shadow( 0px 2px 4px rgba(0, 0, 0, .35));

/*
 * Typography
 */

$defaultFontSize: 18;
$smallFontSize: 16;


/*
 * Spacing
 */

$navMargin: rems(14);
$defaultMargin: rems(14);
$wideMargin: rems($defaultFontSize*2);
$defaultPadding: rems(20);
$weePadding: $navMargin/3;


/*
 * Dimensions
 */

$borderRadius: 20px;
$smBorderRadius: 10px;
$lgBorderRadius: 40px;
$iconRadius: 4px;

$tinyFenceWidth: 480px;
$tightFenceWidth: 768px;
$fenceWidth: 960px;
$wideFenceWidth: 1280px;
$standardWidth: 1280px;
$bodyWidth: 1920px;

// c.f. https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
$breakpoints: (
	standard: (min: 0px, max: 999999px),
	fence: (min: 0px, max: $fenceWidth),
	phone: (min: 0px, max: 600px),
	tablet: (min: 0px, max: 780px),
	tabletPortrait: (min: 601px, max: 900px), // "sm" grid
	tabletLandscape: (min: 901px, max: 1200px), // "md" grid
	desktop: (min: 1201px, max: 1800px), // "desktop" grid
	bigass: (min: 1801px, max: 999999px), // "lg" grid
);